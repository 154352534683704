import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledDateTextInputProps } from './DateTextInput.style.types'

type ThemeOnlyProp = Pick<StyledDateTextInputProps, 'theme'>

export const DateTextInputWrapper = styled.fieldset((): FlattenSimpleInterpolation => css`
  position: relative;
`)

export const Legend = styled.legend((props: ThemeOnlyProp): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.srOnly()}
`)

export const DateTextInput = styled.div((props: StyledDateTextInputProps): FlattenSimpleInterpolation => css`
  color: ${props.theme.color.monochrome8};
  transition: .4s box-shadow ease;
  border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome4};
  border-radius: ${props.theme.border.radius[2]};
  background-color: ${props.theme.color.monochrome1};
  padding: 0 ${props.theme.spacing.fixed[3]}px;
  height: ${props.theme.spacing.fixed[8]}px;
  width: 100%;
  overflow: hidden;

  ${props.theme.mixins.typography.paragraph(3, false)}

  ${props.state && css`
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.theme.color[props.state]};
    padding-right: ${props.theme.spacing.fixed[7]}px;
  `}

  ${props.disabled && css`
    background-color: ${props.theme.color.monochrome3};
    color: ${props.theme.color.monochrome7};
    cursor: not-allowed;
  `}
`)

export const IconWrapper = styled.div((props: ThemeOnlyProp): FlattenSimpleInterpolation => css`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: ${props.theme.spacing.fixed[3]}px;
  pointer-events: none;
`)
