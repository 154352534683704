import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import localIconThemes from '@vfuk/core-icon/dist/themes/Icon.theme'

import { StyledLoadingSpinnerProps } from './LoadingSpinner.style.types'

import localTheme from '../themes/LoadingSpinner.theme'

export const LoadingSpinner = styled.span<StyledLoadingSpinnerProps>((props: StyledLoadingSpinnerProps): FlattenSimpleInterpolation => {
  const localIconTheme = localIconThemes(props.theme)
  const theme = localTheme(props.theme)
  const strokeColor = props.inverse ? localIconTheme.appearance[props.appearance!].inverse.color : localIconTheme.appearance[props.appearance!].color
  return css`
    ${theme.animationStyle(theme.size[props.size!].width, theme.size[props.size!].height, strokeColor)}
  `
})
