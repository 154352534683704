/* stylelint-disable property-no-vendor-prefix */
import { css } from 'styled-components'

const fireflyWrapper = css`
  /* Overrides some default styling for more accurate Firefly testing */
  .firefly-wrapper {
    input,
    textarea {
      color: transparent;
      text-shadow: 0 0 0 #666666;
    }
    [class*='MobileHeader'] {
      display: none;
    }
  }
`

export default fireflyWrapper
