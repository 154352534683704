import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import { IconProps } from './Icon.types'

import * as Styled from './styles/Icon.style'

export class Icon extends PureComponent<IconProps> {
  public static defaultProps: Partial<IconProps> = {
    size: 3,
    isResponsive: true,
    appearance: 'primary',
    inverse: false,
  }

  public render(): ReactNode {
    return (
      <Styled.Icon
        state={this.props.state!}
        aria-hidden='true'
        appearance={this.props.appearance!}
        inverse={this.props.inverse!}
        size={this.props.size!}
        isResponsive={this.props.isResponsive!}
      >
        {this.props.theme!.getIcon(this.props.name)}
      </Styled.Icon>
    )
  }
}

export default withTheme(Icon)
