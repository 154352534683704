import React, { PureComponent, KeyboardEvent } from 'react'
import isFunction from 'lodash/isFunction'

import * as Styled from './styles/FunctionalTabs.style'
import { FunctionalTabsProps, FunctionalTabsState } from './FunctionalTabs.types'

import { TabsContext } from './contexts/TabsContext'
import { getNextTab } from './utils'

export class FunctionalTabs extends PureComponent<FunctionalTabsProps, FunctionalTabsState> {
  public static defaultProps: Partial<FunctionalTabsProps> = {
    // Used to disable home/end buttons and scrolling at the end/beginning of the tablist
    accessibilityInfiniteScroll: true,
  }

  public state: FunctionalTabsState = {
    // The selected tab is, used to show the relative panel
    selectedTabId: this.props.externallySetTabId || '',
    // An array of all the tabIds, used to preserve the order to allow switching between them using the keyboard
    tabList: [],
    // A JS object with { tabId: index } pairs, used to get the index of each tabId
    tabHash: {},
    // A boolean value that indicates if the selected tab needs to be focused on (not necessary when setting it externally)
    needFocus: false,
  }

  public componentDidUpdate(prevProps: FunctionalTabsProps): void {
    this.handleExternalTabChanges(prevProps)
  }

  /**
   * It sets state.selectedTabId to props.externallySetTabId.
   * @param {FunctionalTabsProps} prevProps
   */
  private handleExternalTabChanges(prevProps: FunctionalTabsProps): void {
    const id = this.props.externallySetTabId
    // Only set ID if externallySetTabId is not falsy, it's not equal to selectedTabId and externallySetTabId has changed
    if (!!id && id !== prevProps.externallySetTabId && id !== this.state.selectedTabId) {
      this.setSelectedTabId(id)
    }
  }

  /**
   * It sets selectedTabId and calls the callback onTabClick if specified
   * @param {string} tabId
   * @param {boolean} needFocus - This is necessary since there are cases when you don't want the focus to be on the tab
   */
  private setSelectedTabId = (tabId: string, needFocus: boolean = false): void => {
    this.setState({
      selectedTabId: tabId,
      needFocus,
    })
    if (isFunction(this.props.onTabClick)) {
      this.props.onTabClick(tabId)
    }
  }

  /**
   * This function is necessary to create a reference to each single Tab since they can be nested deeply inside the Tabs component
   * The function is called once by every Tab on mounting. It creates an hash {tabId: index} and a list [tabId]
   * It's used to allow navigation using the arrow keys.
   * @param {string} tabId
   */
  private registerTab = (tabId: string): void => {
    this.setState(
      (prevState): FunctionalTabsState => {
        const tabList = [...prevState.tabList]
        const tabHash = { ...prevState.tabHash }
        // Push returns the length of the array
        const pos = tabList.push(tabId) - 1
        tabHash[tabId] = pos
        return {
          ...this.state,
          tabList,
          tabHash,
          selectedTabId: prevState.selectedTabId ? prevState.selectedTabId : tabId,
        }
      },
    )
  }

  /**
   * This function is used to get the next tab to be selected
   * @param {KeyboardEvent} event
   */
  private setNextTab = (event: KeyboardEvent): void => {
    // @bug-fix
    // if user pressed 'tab' then do nothing and allow tab to jump to next element
    // without this user will be 'stuck' on the web element when using tab to navigate
    if (event.keyCode === 9) return
    // I previously removed preventDefault, but after allowing Home and End to be used on Tab it became necessary
    event.preventDefault()
    const keyIndex = this.state.tabHash[this.state.selectedTabId]
    const nextTab = getNextTab(event.keyCode, this.state.tabList.length, keyIndex, this.props.accessibilityInfiniteScroll)
    if (nextTab >= 0) {
      this.setSelectedTabId(this.state.tabList[nextTab], true)
    }
  }

  public render(): React.ReactNode {
    return (
      <TabsContext.Provider
        value={{
          selectedTabId: this.state.selectedTabId,
          setSelectedTabId: this.setSelectedTabId,
          registerTab: this.registerTab,
          setNextTab: this.setNextTab,
          needFocus: this.state.needFocus,
          tabsNumberTotal: this.state.tabList.length,
          tabHash: this.state.tabHash,
        }}
      >
        <Styled.Tabs>{this.props.children}</Styled.Tabs>
      </TabsContext.Provider>
    )
  }
}
