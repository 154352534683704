/* stylelint-disable property-no-vendor-prefix */
import { css } from 'styled-components'

const fireflyImageReplace = css`
  .firefly-background-image {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../assets/background-gradient.jpg') center center;
    background-size: cover !important;
  }
`

export default fireflyImageReplace
