import baseFont from '@vfuk/core-styles/dist/constants/baseFont'
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { ContainerTheme } from './Container.theme.types'

const containerLocal = (theme: Theme): ContainerTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1,
        color: baseFont.color,
        border: {
          color: theme.color.monochrome4,
        },
      },
      secondary: {
        backgroundColor: theme.color.primary3,
        color: theme.color.primary2,
        border: {
          color: 'transparent',
        },
      },
      alt1: {
        backgroundColor: theme.color.monochrome10,
        color: theme.color.primary2,
        border: {
          color: 'transparent',
        },
      },
      alt2: {
        backgroundColor: `linear-gradient(${theme.color.gradient1})`,
        color: theme.color.primary2,
        border: {
          color: 'transparent',
        },
      },
      alt3: {
        backgroundColor: `linear-gradient(${theme.color.gradient2})`,
        color: theme.color.primary2,
        border: {
          color: 'transparent',
        },
      },
      alt4: {
        backgroundColor: theme.color.primary1,
        color: theme.color.primary2,
        border: {
          color: 'transparent',
        },
      },
    },
  }
}

export default containerLocal
