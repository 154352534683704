import React, { PureComponent, ReactNode } from 'react'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/IconButton.style'

import { IconButtonProps } from './IconButton.types'

export default class IconButton extends PureComponent<IconButtonProps> {
  public static defaultProps: Partial<IconButtonProps> = {
    appearance: 'primary',
    size: 3,
    htmlAttributes: {},
    isResponsive: true,
    inverse: false,
  }

  public render(): ReactNode {
    return (
      <Styled.IconButton
        label={this.props.srText}
        target={this.props.htmlAttributes!.target}
        type={this.props.htmlAttributes!.type}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
        disabled={this.props.state === 'disabled'}
        onInteraction={this.props.onClick}
        to={this.props.to}
        href={this.props.url}
      >
        <Icon
          name={this.props.name}
          appearance={this.props.appearance}
          inverse={this.props.inverse}
          size={this.props.size}
          isResponsive={this.props.isResponsive!}
        />
      </Styled.IconButton>
    )
  }
}
