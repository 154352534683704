/* eslint-disable max-len */
import { BorderConfig, Colors } from '@vfuk/core-styles/dist/mixins/themedBorderGenerator.types'
import { AppearanceKeys as IconAppearanceKeys } from '@vfuk/core-icon/dist/themes/Icon.theme.types'
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { AppearanceProps, ButtonTheme, StateProps } from './Button.theme.types'

type AppearanceNoStateProps = Omit<AppearanceProps, 'state'>

const getDoubleBorder = (theme: Theme, colors: Colors): BorderConfig => {
  return {
    colors,
    style: theme.border.style[2],
    width: theme.border.width[1],
  }
}

const getSuccessState = (theme: Theme, buttonState: AppearanceNoStateProps): StateProps => {
  const borderColors = {
    ...buttonState.border.colors,
    backgroundColor: theme.color.success,
    innerBorderColor: theme.color.success,
  }

  const hover = {
    ...buttonState.hover,
    color: theme.color.primary3,
  }

  return {
    ...buttonState,
    border: {
      ...buttonState.border,
      colors: borderColors,
    },
    color: theme.color.primary3,
    hover,
    iconAppearance: 'primary',
    opacity: 1,
  }
}

const getDisabledState = (theme: Theme, buttonState: AppearanceNoStateProps): StateProps => {
  return {
    ...buttonState,
    opacity: 0.3,
  }
}

type GetAppearanceOptions = {
  borderColors: Colors
  color: string
  iconAppearance: IconAppearanceKeys
}

const getAppearance = (theme: Theme, { borderColors, color, iconAppearance }: GetAppearanceOptions): AppearanceProps => {
  const appearance: AppearanceNoStateProps = {
    backgroundColor: 'transparent',
    border: getDoubleBorder(theme, borderColors),
    color,
    hover: {
      color,
      backgroundColor: 'transparent',
      iconAppearance,
    },
    iconAppearance,
    inverseIcon: false,
  }

  return {
    ...appearance,
    state: {
      success: getSuccessState(theme, appearance),
      selected: getSuccessState(theme, appearance),
      disabled: getDisabledState(theme, appearance),
    },
  }
}

const buttonLocal = (theme: Theme): ButtonTheme => {
  const primaryAppearance = getAppearance(theme, {
    borderColors: {
      backgroundColor: theme.color.primary3,
      innerBorderColor: theme.color.primary3,
      outerBorderColor: theme.color.secondary3,
    },
    color: theme.color.primary2,
    iconAppearance: 'secondary',
  })

  const primaryInverseAppearance = getAppearance(theme, {
    borderColors: {
      backgroundColor: theme.color.secondary1,
      innerBorderColor: theme.color.secondary1,
      outerBorderColor: theme.color.secondary3,
    },
    color: theme.color.primary3,
    iconAppearance: 'primary',
  })

  const secondaryAppearance = getAppearance(theme, {
    borderColors: {
      backgroundColor: 'transparent',
      innerBorderColor: theme.color.primary3,
      outerBorderColor: theme.color.secondary3,
    },
    color: theme.color.primary3,
    iconAppearance: 'primary',
  })

  const secondaryInverseAppearance = getAppearance(theme, {
    borderColors: {
      backgroundColor: 'transparent',
      innerBorderColor: theme.color.primary2,
      outerBorderColor: theme.color.secondary3,
    },
    color: theme.color.primary2,
    iconAppearance: 'secondary',
  })

  return {
    appearance: {
      primary: {
        ...primaryAppearance,
        inverse: primaryInverseAppearance,
      },
      secondary: {
        ...secondaryAppearance,
        inverse: secondaryInverseAppearance,
      },
      alt1: {
        ...primaryAppearance,
        inverse: primaryInverseAppearance,
      },
      alt2: {
        ...primaryAppearance,
        inverse: primaryInverseAppearance,
      },
      alt3: {
        ...primaryAppearance,
        inverse: primaryInverseAppearance,
      },
    },
    minWidth: '192px',
    inverse: false,
    borderRadius: theme.border.radius[0],
    focus: theme.color.focus,
  }
}

export default buttonLocal
