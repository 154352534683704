import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledIconButtonProps } from './IconButton.style.types'

export const IconButton = styled(Interaction)((props: StyledIconButtonProps): FlattenSimpleInterpolation => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  position: relative;

  ${props.disabled && css`
    cursor: inherit;
  `}
`)
