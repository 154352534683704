import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'
import createMixin from '@vfuk/core-styles/dist/utils/createMixin'

import localTheme from '../../themes/Icon.theme'
import { IconSizeKeys, Size } from '../../themes/Icon.theme.types'

export default createMixin(
  (theme: Theme, size: IconSizeKeys, isResponsive: boolean): FlattenSimpleInterpolation => {
    const iconSize: Size = localTheme(theme).size[size]
    if (!isResponsive) {
      return css`
        width: ${iconSize.xl}px;
        height: ${iconSize.xl}px;
      `
    }

    return css`
      width: ${iconSize.sm}px;
      height: ${iconSize.sm}px;

      ${theme.mixins.respondTo.md(css`
        width: ${iconSize.md}px;
        height: ${iconSize.md}px;
      `)}

      ${theme.mixins.respondTo.lg(css`
        width: ${iconSize.lg}px;
        height: ${iconSize.lg}px;
      `)}

    ${theme.mixins.respondTo.xl(css`
        width: ${iconSize.xl}px;
        height: ${iconSize.xl}px;
      `)}
    `
  },
)
