import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledCalendarPickerProps } from './CalendarPicker.style.types'

import ChevronLeft from '../assets/chevron-left.svg'
import ChevronRight from '../assets/chevron-right.svg'

export const CalendarPicker = styled.div((props: StyledCalendarPickerProps): FlattenSimpleInterpolation => css`
  position: relative;
  z-index: 10;

  .react-datepicker-wrapper {
    width: 100%;

    ${props.theme.mixins.respondTo.md(css`
      width: 290px;
    `)}
  }

  .react-datepicker {
    right: 0;

    ${props.theme.mixins.respondTo.md(css`
      position: relative;
      left: 0 !important;
    `)}

    ${props.theme.mixins.elevation(true)};
    font-family: ${props.theme.baseFont.fontFamily};
  }

  .react-datepicker__day {
    border-radius: 50%;
    margin: ${props.theme.spacing.fixed[1]}px;
    font-size: 16px;

    ${props.theme.mixins.respondTo.md(css`
      margin: ${props.theme.spacing.fixed[2]}px;
    `)}

    &:hover {
      background-color: ${props.theme.color.secondary8};
      color: ${props.theme.color.primary2};
    }

    &--selected {
      background-color: ${props.theme.color.accent2};

      &:focus {
        box-shadow: 0 0 0 1px ${props.theme.color.primary2}, 0 0 0 4px ${props.theme.color.focus};
        outline: 3px solid transparent;
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${props.theme.color.focus};
    &:focus {
      box-shadow: 0 0 0 1px ${props.theme.color.primary2}, 0 0 0 4px ${props.theme.color.focus};
      outline: 3px solid transparent;
    }
  }

  .react-datepicker__header {
    background-color: ${props.theme.color.primary2};
    border-bottom: none;
  }

  .react-datepicker__day-name {
    height: ${props.theme.spacing.fixed[5]}px;
    font-size: 16px;
    margin: ${props.theme.spacing.fixed[1]}px;
    width: 28px;

    ${props.theme.mixins.respondTo.md(css`
      margin: ${props.theme.spacing.fixed[2]}px;
    `)}
  }

  .react-datepicker-year-header,
  .react-datepicker__current-month {
    height: ${props.theme.spacing.fixed[3]}px;
    font-size: 20px;
    margin: 12px;

    ${props.theme.mixins.respondTo.md(css`
      height: ${props.theme.spacing.fixed[3]}px;
    `)}
  }

  .react-datepicker__triangle {
    left: 90% !important;
    border-bottom-color: ${props.theme.color.primary2};

    ${props.theme.mixins.respondTo.md(css`
      left: 84% !important;
    `)}
  }

  .react-datepicker__month-text {
    font-size: ${props.theme.typography.paragraph[3].fontDesktop};
    border-radius: ${props.theme.spacing.fixed[5]}px;
    margin: ${props.theme.spacing.fixed[2]}px ${props.theme.spacing.fixed[3]}px;
    height: 26px;
    width: ${props.theme.spacing.fixed[8]}px;

    ${props.theme.mixins.respondTo.md(css`
      margin: ${props.theme.spacing.fixed[2]}px ${props.theme.spacing.fixed[5]}px;
    `)}

    &:hover {
      background-color: ${props.theme.color.secondary8};
      color: ${props.theme.color.primary2};
    }
  }

  .react-datepicker__month--selected {
    background-color: ${props.theme.color.accent2};
  }

  .react-datepicker__navigation {
    margin: 12px;
    border: none;
    width: ${props.theme.spacing.fixed[3]}px;
    height: ${props.theme.spacing.fixed[3]}px;
    background: transparent;
    background-size: contain;

    &--previous {
      background-image: url(${ChevronLeft});
    }

    &--next {
      background-image: url(${ChevronRight});
    }

    &:focus {
      box-shadow: 0 0 0 3px ${props.theme.color.focus};
      outline: 3px solid transparent;
    }
  }
`)
