import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'

import { StyledLegalLinksProps } from './LegalLinks.style.types'

export const LegalLink = styled(ListItem)((props: StyledLegalLinksProps): FlattenSimpleInterpolation => css`
  font-size: ${props.theme.mixins.typography.paragraph(3, false)};

  > a {
    color: ${props.theme.color.monochrome4};
    display: inline-block;
    width: fit-content;
    margin-bottom: ${props.theme.spacing.fixed[2]}px;
    border-bottom: ${props.theme.border.style[1]} ${props.theme.border.width[1]} rgba(0, 0, 0, 0);
    transition: color 0.25s, border-bottom 0.25s;

    &:hover {
      color: ${props.theme.color.primary2};
      border-bottom: ${props.theme.border.style[1]} ${props.theme.border.width[1]} ${props.theme.color.primary2};
    }
  }

  ${props.theme.mixins.respondTo.md(css`
    display: flex;

    > a {
      margin-bottom: 0;
    }

    &::after {
      content: '|';
      color: ${props.theme.color.monochrome4};
      padding: 0 ${props.theme.spacing.fixed[5]}px;
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  `)}
`)

export const LegalLinks = styled(ListGroup)((props: StyledLegalLinksProps): FlattenSimpleInterpolation => css`
  margin-bottom: ${props.theme.spacing.fixed[6]}px;
  display: flex;
  flex-direction: column;

  ${props.theme.mixins.respondTo.md(css`
    flex-direction: row;
    margin-bottom: ${props.theme.spacing.fixed[7]}px;
  `)}

  > li {
    &:last-child {
      a {
        margin-bottom: 0;
      }
    }
  }
`)
