import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledFooterProps } from './Footer.style.types'

export const Footer = styled.div((props: StyledFooterProps): FlattenSimpleInterpolation => css`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${props.theme.spacing.fixed[6]}px ${props.theme.spacing.fixed[3]}px;
  background-color: ${props.theme.color.monochrome8};

  ${props.theme.mixins.respondTo.md(css`
    padding: ${props.theme.spacing.fixed[7]}px ${props.theme.spacing.fixed[3]}px;
  `)}
`)

export const FooterContainer = styled.div((props: StyledFooterProps): FlattenSimpleInterpolation => css`
  max-width: ${props.theme.gridConfig.springWidth}px;
`)

export const SocialAndCommunity = styled.div((props: StyledFooterProps): FlattenSimpleInterpolation => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props.theme.spacing.fixed[5]}px;

  [class*='Social'] {
    margin-bottom: ${props.theme.spacing.fixed[6]}px;
  }

  ${props.theme.mixins.respondTo.md(css`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${props.theme.spacing.fixed[7]}px;

    [class*='Social'] {
      margin-bottom: 0;
    }
  `)}

  ${props.theme.mixins.respondTo.lg(css`
    margin-bottom: ${props.theme.spacing.fixed[6]}px;
  `)}
`)

export const Copyright = styled.div((props: StyledFooterProps): FlattenSimpleInterpolation => css`
  margin-top: ${props.theme.spacing.fixed[5]}px;
  color: ${props.theme.color.monochrome4};

  ${props.theme.mixins.respondTo.md(css`
    margin-top: 0;
  `)}

  ${props.theme.mixins.respondTo.lg(css`
    margin-bottom: ${props.theme.spacing.fixed[3]}px;;
  `)}
`)
