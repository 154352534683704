import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { LinkWithIconTheme } from './LinkWithIcon.theme.types'

const linkWithIconLocal = (theme: Theme): LinkWithIconTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome8,
        iconColor: 'secondary',
        inverse: {
          color: theme.color.primary2,
          iconColor: 'primary',
          inverseIcon: true,
        },
      },
      secondary: {
        color: theme.color.monochrome8,
        iconColor: 'primary',
        inverse: {
          color: theme.color.primary2,
          iconColor: 'primary',
          inverseIcon: true,
        },
      },
    },
    state: {
      selected: {
        color: theme.color.primary1,
        iconColor: 'secondary',
        inverse: {
          color: theme.color.primary2,
          iconColor: 'primary',
          inverseIcon: true,
        },
      },
      disabled: {
        color: theme.color.monochrome7,
        iconColor: theme.color.monochrome7,
        inverse: {
          color: theme.color.monochrome7,
          iconColor: theme.color.monochrome7,
        },
      },
    },
  }
}

export default linkWithIconLocal
