/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledCollapsibleLinkListGroupProps } from './CollapsibleLinkListGroup.style.types'

type CollapsibleLinkListGroupProps = Pick<StyledCollapsibleLinkListGroupProps, 'theme'>
export const CollapsibleLinkListGroup = styled.div((props: CollapsibleLinkListGroupProps): FlattenSimpleInterpolation => css`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props.theme.spacing.fixed[6]}px;
`)

type CollapsibleLinkListProps = Pick<StyledCollapsibleLinkListGroupProps, 'theme' | 'isActive'>
export const CollapsibleLinkList = styled.div((props: CollapsibleLinkListProps): FlattenSimpleInterpolation => css`
  border-top: ${props.theme.border.style[1]} ${props.theme.border.width[1]} ${props.theme.color.monochrome4};
  
  > div {
    padding: ${props.theme.spacing.fixed[2]}px 0;
  }

  &:last-child {
    > div {
      padding-bottom: 0;
    }
  }

  [class*='Icon'] {
    svg {
      fill: ${props.theme.color.monochrome4};

      ${props.isActive && css`
        fill: ${props.theme.color.primary2};
      `}
    }
  }
`)

type LinkListContainerProps = Pick<StyledCollapsibleLinkListGroupProps, 'theme'>
export const LinkListContainer = styled.div((props: LinkListContainerProps): FlattenSimpleInterpolation => css`
  margin: ${props.theme.spacing.fixed[2]}px 0;
`)

type HeadingContainerProps = Pick<StyledCollapsibleLinkListGroupProps, 'theme' | 'isActive'>
export const HeadingContainer = styled.div((props: HeadingContainerProps): FlattenSimpleInterpolation => css`
  [class*='Heading'] {
    color: ${props.theme.color.monochrome4};
  }

  ${props.isActive && css`
    [class*='Heading'] {
      color: ${props.theme.color.primary2};
    }
  `}
`)
