import React from 'react'
import { css, FlattenSimpleInterpolation } from 'styled-components'

import createMixin from '@vfuk/core-styles/dist/utils/createMixin'
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { LoadingSpinnerTheme, AnimationStyleMixin } from './LoadingSpinner.theme.types'

const loadingSpinnerTheme = (voxiTheme: Theme): LoadingSpinnerTheme => {
  const svg = (
    <svg viewBox='25 25 50 50'>
      <circle cx='50' cy='50' r='20' />
    </svg>
  )
  return {
    svg,
    size: {
      1: {
        width: 16,
        height: 16,
      },
      2: {
        width: 24,
        height: 24,
      },
      3: {
        width: 32,
        height: 32,
      },
      4: {
        width: 40,
        height: 40,
      },
      5: {
        width: 48,
        height: 48,
      },
    },
    animationStyle: createMixin(
      (theme: Theme, width: number, height: number, strokeColor: string): FlattenSimpleInterpolation =>
        css`
          @supports (display: grid) {
            display: inline-block;
          }
          display: none;
          width: ${width}px;
          height: ${height}px;

          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
          @keyframes dash {
            0% {
              stroke-dasharray: 1, 200;
              stroke-dashoffset: 0;
            }
            50% {
              stroke-dasharray: 90, 200;
              stroke-dashoffset: -35px;
            }
            100% {
              stroke-dashoffset: -125px;
            }
          }

          svg {
            width: 100%;
            height: 100%;
            transform-origin: center;
            animation: rotate 2s linear infinite;

            > circle {
              fill: none;
              stroke-width: 5px;
              stroke-dasharray: 1, 200;
              stroke-dashoffset: 0;
              stroke: ${strokeColor};
              animation: dash 1.5s ease-in-out infinite;
            }
          }
        `,
    ) as AnimationStyleMixin,
  }
}

export default loadingSpinnerTheme
