import React, { ReactElement, Fragment } from 'react'

import IconButton from '@vfuk/core-icon-button'

import * as Styled from './styles/Social.style'

import { SocialProps } from './Social.types'

const Social: React.FC<SocialProps> = ({ socialButtons, onInteractionClick }: SocialProps): ReactElement => {
  return (
    <Styled.SocialContainer>
      {socialButtons.map((socialButton, key) => {
        return (
          <Fragment key={key}>
            <IconButton
              name={socialButton.name}
              srText={socialButton.srText}
              url={socialButton.url}
              htmlAttributes={socialButton.htmlAttributes}
              onClick={onInteractionClick}
              size={5}
              inverse
            />
          </Fragment>
        )
      })}
    </Styled.SocialContainer>
  )
}

export default Social
