import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSocialProps } from './Social.style.types'

export const SocialContainer = styled.div((props: StyledSocialProps): FlattenSimpleInterpolation => css`
  display: flex;

  > [class*='IconButton'] {
    margin-right: ${props.theme.spacing.fixed[5]}px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${props.theme.mixins.respondTo.md(css`
    > [class*='IconButton'] {
      margin-right: ${props.theme.spacing.fixed[3]}px;

      &:last-child {
        margin-right: 0;
      }
    }
  `)}
`)
