import { ThemeName } from '@vfuk/core-styles/dist/constants/themeName.types'
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import WS2 from './Container.ws2.theme'
import WS10 from './Container.ws10.theme'
import VOXI from './Container.voxi.theme'

import { ContainerTheme } from './Container.theme.types'

type LocalThemes = {
  [P in ThemeName]: (theme: Theme) => ContainerTheme
}

const localThemes: LocalThemes = {
  WS2,
  WS10,
  VOXI,
}

const getLocalTheme = (theme: Theme): ContainerTheme => {
  return localThemes[theme.name](theme)
}

export default getLocalTheme
