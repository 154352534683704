/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledLinkWithIconProps } from './LinkWithIcon.style.types'

import localThemes from '../themes/LinkWithIcon.theme'

function getStateColor(props: LinkWithIconTextProps): string {
  const localTheme = localThemes(props.theme)

  return props.inverse ? localTheme.state[props.state!].inverse.color : localTheme.state[props.state!].color
}

type LinkWithIconProps = Pick<StyledLinkWithIconProps, 'appearance' | 'theme' | 'inverse' | 'state' | 'isLink'>
export const LinkWithIcon = styled(Interaction)((props: LinkWithIconProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    display: inline-flex;
    align-items: center;
    color: ${localTheme.appearance[props.appearance!].color};

    ${props.inverse && css`
      color: ${localTheme.appearance[props.appearance!].inverse.color};
    `}

    ${props.state === 'disabled' && !props.isLink && css`
      cursor: default;
    `}
  `
})

type IconContainerProps = Pick<StyledLinkWithIconProps, 'theme' | 'state' | 'inverse' | 'justify' | 'size'| 'isLink'>
export const IconContainer = styled.div((props: IconContainerProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    display: inline-flex;

    ${props.justify === 'left' && css`
      order: 0;
      margin-right: ${props.theme.spacing.fixed[2]}px;

      ${props.size! > 2 && css`
        margin-right: ${props.theme.spacing.fixed[3]}px;
      `}
    `}

    ${(props.justify === 'right' || !props.justify) && css`
      order: 1;
      margin-left: ${props.theme.spacing.fixed[2]}px;

      ${props.size! > 2 && css`
        margin-left: ${props.theme.spacing.fixed[3]}px;
      `}
    `}

    ${props.state === 'disabled' && !props.isLink && css`
      [class*='Icon'] {
        fill: ${props.inverse ? localTheme.state[props.state].inverse.iconColor : localTheme.state[props.state].iconColor};
      }
    `}
  `
})

type LinkWithIconTextProps = Pick<StyledLinkWithIconProps, 'theme' | 'size' | 'state' | 'inverse' | 'isLink'>
export const LinkWithIconText = styled.div((props: LinkWithIconTextProps): FlattenSimpleInterpolation => css`
  display: flex;
  line-height: initial;
  border-bottom: ${props.theme.border.width[1]} ${props.theme.border.style[1]} transparent;

  ${props.state && props.state !== 'disabled' && css`
    color: ${getStateColor(props)};

    ${props.state === 'selected' && css`
      border-bottom: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${getStateColor(props)};
    `}
  `}

  ${props.state === 'disabled' && !props.isLink && css`
    color: ${getStateColor(props)};
  `}

  ${props.size === 1 && css`
    font-size: ${props.theme.typography.paragraph[3].fontDesktop};
  `}

  ${props.size! > 1 && css`
    ${props.theme.mixins.typography.heading((props.size! - 1) as 1 | 2 | 3 | 4, false)};
  `}

  ${props.size! > 2 && css`
    border-width: ${props.theme.border.width[2]};
  `}

  /* Media queries required in order to override typography mixin specificity in Paragraph and Heading */
  ${props.theme.mixins.respondTo.md(css`
    line-height: initial;
  `)}

  ${props.theme.mixins.respondTo.lg(css`
    line-height: initial;
  `)}
`)
