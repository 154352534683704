import { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import overlayController from '@vfuk/core-overlay-controller/dist/controller/OverlayController'

import { ModalProps } from './Modal.types'

const Modal: React.FC<ModalProps> = ({
  isClosable = true,
  isOpen = false,
  onCloseCb = null,
  initialFocusId = null,
  focusEnabledRefs,
  children,
  animate = true,
  size = 1,
  srName,
  appearance = 'primary',
  type = 'modal',
  side = 'right',
  id,
}: ModalProps) => {
  const modalId = useRef(id || uuid())

  useEffect(() => {
    if (isOpen) {
      overlayController.setConfig(modalId.current, {
        isShowing: true,
        isClosable,
        onCloseCb,
        initialFocusId,
        focusEnabledRefs,
        content: children,
        animate,
        srName,
        type,
        renderOptions: {
          size,
          appearance,
          side,
        },
      })
    } else {
      overlayController.hide(modalId.current)
    }
  }, [isOpen, isClosable, onCloseCb, initialFocusId, focusEnabledRefs, children, animate, size, srName, appearance, side, type])

  // Destructor to be run when the component unmounts
  useEffect(() => {
    return (): void => {
      if (isOpen) {
        overlayController.hide(modalId.current)
      }
    }
  }, [])

  return null
}

export default Modal
