import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Container from '@vfuk/core-container'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'
import Link from '@vfuk/core-link'
import IconButton from '@vfuk/core-icon-button'

import * as Styled from './styles/SimpleNotification.style'

import { SimpleNotificationProps } from './SimpleNotification.types'

import localTheme from './themes/SimpleNotification.theme'

export class SimpleNotification extends PureComponent<SimpleNotificationProps> {
  public render(): ReactNode {
    const appearance = localTheme.appearance[this.props.appearance!]
    return (
      <Container appearance={appearance.containerAppearance} elevation={true}>
        <Styled.NotificationBody>
          <If condition={this.props.icon}>
            <Styled.NotificationIcon>
              <Icon name={this.props.icon!.name} size={3} appearance={appearance.iconAppearance} inverse={appearance.inverseIcon} />
            </Styled.NotificationIcon>
          </If>
          <Paragraph noMargin>
            <Choose>
              <When condition={this.props.link}>
                {`${this.props.text} `}
                <Link {...this.props.link} appearance={appearance.linkAppearance} inverse={appearance.inverseText} showUnderline />
              </When>
              <Otherwise>{this.props.text}</Otherwise>
            </Choose>
          </Paragraph>
          <If condition={this.props.closeButton}>
            <Styled.CloseButton>
              <IconButton
                srText={this.props.closeButton!.srText}
                name='close'
                appearance={appearance.iconAppearance}
                inverse={appearance.inverseIcon}
                onClick={this.props.closeButton!.onClick}
              />
            </Styled.CloseButton>
          </If>
        </Styled.NotificationBody>
      </Container>
    )
  }
}

export default withTheme(SimpleNotification)
