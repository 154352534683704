import React, { PureComponent, ReactNode, Fragment } from 'react'

import * as Styled from './styles/Overlay.style'

import { OverlayProps } from './Overlay.types'

export default class Overlay extends PureComponent<OverlayProps> {
  public static defaultProps: Partial<OverlayProps> = {
    position: 'fixed',
    show: false,
    overflowHidden: false,
    zIndex: 10,
  }

  public render(): ReactNode {
    if (!this.props.show) return null

    return (
      <Fragment>
        <Styled.HideOverflow show={this.props.show} overflowHidden={this.props.overflowHidden} />
        <Styled.Overlay onClick={this.props.onClick} position={this.props.position} zIndex={this.props.zIndex!} />
      </Fragment>
    )
  }
}
