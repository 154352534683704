import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import iconSizes from './mixins/iconSizes'

import { StyledIconProps } from './Icon.style.types'

import localThemes from '../themes/Icon.theme'

export const Icon = styled.span<StyledIconProps>((props: StyledIconProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    display: inline-flex;
    vertical-align: text-bottom;
    fill: ${localTheme.appearance[props.appearance!].color};

    span {
      display: inline-flex;
    }

    svg {
      ${iconSizes(props.size, props.isResponsive)}
    }

    ${props.inverse && `
      fill: ${localTheme.appearance[props.appearance!].inverse.color};
    `}

    ${props.state && `
      fill: ${localTheme.state[props.state].color};
    `}
  `
})
