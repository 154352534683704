import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListGroup from '@vfuk/core-list-group'

import { StyledLinkListProps } from './LinkList.style.types'

export const LinkList = styled(ListGroup)((props: StyledLinkListProps): FlattenSimpleInterpolation => css`
  li {
    color: ${props.theme.color.monochrome4};
    margin-bottom: ${props.theme.spacing.fixed[2]}px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      ${props.theme.mixins.typography.paragraph(3, false)};
      display: inline;
      border-bottom: ${props.theme.border.style[1]} ${props.theme.border.width[1]} transparent;
      transition: color 0.25s, border-bottom 0.25s;
    }

    &:hover {
      a {
        color: ${props.theme.color.primary2};
        border-bottom: ${props.theme.border.style[1]} ${props.theme.border.width[1]} ${props.theme.color.primary2};
      }
    }
  }
`)
