import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledUnitInputProps, StyledUnitInputWrapperProps } from './UnitInput.style.types'

export const UnitInput = styled.input((props: StyledUnitInputProps): FlattenSimpleInterpolation => css`
  position: relative;
  border: 0;
  border-radius: ${props.theme.border.radius[2]};
  background-color: ${props.theme.color.monochrome1};
  padding: 0 ${props.theme.spacing.fixed[2]}px;
  height: ${props.theme.spacing.fixed[8] - 12}px;
  text-align: center;

  ${props.unitType !== 'year' && css`
    width: ${props.theme.spacing.fixed[7]}px;

    ${props.theme.mixins.respondTo.md(css`
      width: 60px;
    `)}
  `}

  ${props.unitType === 'year' && css`
    width: 49px;

    ${props.theme.mixins.respondTo.md(css`
      width: 60px;
    `)}
  `}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props.theme.color.focus};
  }

  ${props.disabled && css`
    background-color: ${props.theme.color.monochrome3};
    color: ${props.theme.color.monochrome7};
    cursor: not-allowed;
  `}
`)

export const UnitInputContainer = styled.div((props: StyledUnitInputWrapperProps): FlattenSimpleInterpolation => css`
  display: inline-block;
  padding-top: 5px;

  ${props.showDelimeter && css`
    &::after {
      margin: 0 ${props.theme.spacing.fixed[2]}px;
      content: '|';
    }
  `}
`)

type LabelProps = Pick<StyledUnitInputProps, 'theme'>
export const Label = styled.label((props: LabelProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.srOnly()}
`)
