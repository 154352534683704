import React, { PureComponent, ReactNode } from 'react'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import dateFormatter from '@vfuk/core-date-text-input/dist/utils/dateFormatter'

import DateTextInputWrapper from './components/DateTextInputWrapper'

import * as Styled from './styles/CalendarPicker.style'

import { CalendarPickerProps } from './CalendarPicker.types'

export default class CalendarPicker extends PureComponent<CalendarPickerProps> {
  public static defaultProps: Partial<CalendarPickerProps> = {
    disabled: false,
    showMonthYearPicker: false,
    shouldCloseOnSelect: true,
  }

  handleOnChange = (value: Date, dateString?: string): void => {
    const formattedDateString = dateString || dateFormatter(value, this.props.showMonthYearPicker).toString()
    this.props.onChange(value, formattedDateString)
  }

  handleCalendarOpen = (): void => {
    if (this.props.onCalendarOpen) {
      this.props.onCalendarOpen()
    }
  }

  handleCalendarClose = (): void => {
    if (this.props.onCalendarClose) {
      this.props.onCalendarClose()
    }
  }

  handleOnDatePickerChange = (date: Date): void => {
    this.handleOnChange(date)
  }

  public render(): ReactNode {
    return (
      <Styled.CalendarPicker>
        <DatePicker
          selected={this.props.value}
          dateFormat={this.props.showMonthYearPicker ? 'MM/yyyy' : 'dd/MM/yyyy'}
          id={this.props.id}
          onChange={this.handleOnDatePickerChange}
          disabled={this.props.disabled}
          includeDates={this.props.includeDates}
          excludeDates={this.props.excludeDates}
          onCalendarOpen={this.handleCalendarOpen}
          onCalendarClose={this.handleCalendarClose}
          openToDate={this.props.openToDate}
          shouldCloseOnSelect={this.props.shouldCloseOnSelect}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          filterDate={this.props.filterDate}
          onBlur={this.props.onBlur}
          popperPlacement={this.props.popupPosition}
          customInput={
            <DateTextInputWrapper
              id={`${this.props.id}-date-text-input`}
              name={this.props.name}
              required={this.props.required}
              value={this.props.value}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              includeDates={this.props.includeDates}
              excludeDates={this.props.excludeDates}
              filterDate={this.props.filterDate}
              onInvalidInputEntry={this.props.onInvalidInputEntry}
              showMonthYearPicker={this.props.showMonthYearPicker}
              state={this.props.state}
              errorMessages={this.props.errorMessages}
              onDateChange={this.handleOnChange}
            />
          }
          showMonthYearPicker={this.props.showMonthYearPicker}
        />
      </Styled.CalendarPicker>
    )
  }
}
