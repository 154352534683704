import React, { ReactElement, FC } from 'react'
import Modal from '@vfuk/core-modal'
import Icon from '@vfuk/core-icon'
import Heading from '@vfuk/core-heading'
import Paragraph from '@vfuk/core-paragraph'
import Button from '@vfuk/core-button'
import ButtonWithIcon from '@vfuk/core-button-with-icon'

import * as Styled from './styles/ErrorStatusModal.style'

import { ErrorStatusModalProps } from './ErrorStatusModal.types'

const ErrorStatusModal: FC<ErrorStatusModalProps> = ({
  srName,
  isOpen,
  onCloseCb,
  headingText,
  text,
  button,
}: ErrorStatusModalProps): ReactElement => {
  return (
    <Modal srName={srName} isOpen={isOpen} isClosable={true} onCloseCb={onCloseCb} size={4}>
      <Styled.ModalBody>
        <Styled.IconContainer>
          <Icon name='warning' size={5} appearance='primary' />
        </Styled.IconContainer>
        <Styled.ContentWrapper>
          <Heading text={headingText} appearance='primary' level={2} />
          <Paragraph size={3}>{text}</Paragraph>
          <Choose>
            <When condition={button?.icon}>
              <ButtonWithIcon
                id={button.id}
                appearance='primary'
                text={button.text}
                onClick={button.onClick}
                href={button.href}
                loading={button.loading}
                state={button.state}
                to={button.to}
                htmlAttributes={button.htmlAttributes}
                icon={{
                  name: button.icon!.name,
                  justify: button.icon!.justify,
                }}
              />
            </When>
            <Otherwise>
              <Button
                id={button.id}
                appearance='primary'
                text={button.text}
                onClick={button.onClick}
                href={button.href}
                loading={button.loading}
                state={button.state}
                to={button.to}
                htmlAttributes={button.htmlAttributes}
              />
            </Otherwise>
          </Choose>
        </Styled.ContentWrapper>
      </Styled.ModalBody>
    </Modal>
  )
}

export default ErrorStatusModal
