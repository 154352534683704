import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledTabTitleProps } from './TabTitle.style.types'

type TabTitleWrapperProps = Pick<StyledTabTitleProps, 'theme'>
export const TabTitleWrapper = styled.div((props: TabTitleWrapperProps): FlattenSimpleInterpolation => css`
  > button:first-child {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus};
    }
  }
`)

export const TabTitle = styled.div((props: StyledTabTitleProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;
  position: relative;
  ${props.theme.mixins.typography.heading(1, false)}
  padding: ${props.theme.spacing.fixed[2]}px 12px;
  border-bottom: 4px ${props.theme.border.style[1]} transparent;
  color: ${props.theme.color.monochrome8};
  transition: 0.4s all ease;

  ${props.isActiveTab && css`
    border-bottom: 4px ${props.theme.border.style[1]} ${props.theme.color.primary1};
    font-weight: ${props.theme.fontWeight[3]};
  `}

  ${props.theme.mixins.respondTo.md(css`
    padding: ${props.theme.spacing.fixed[3]}px ${props.theme.spacing.fixed[5]}px;
  `)}

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 12px;

    ${props.theme.mixins.respondTo.md(css`
      padding: 0 ${props.theme.spacing.fixed[5]}px;
    `)}
  }

  &::after {
    display: block;
    content: attr(title);
    font-weight: ${props.theme.fontWeight[3]};
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`)
