import React, { PureComponent, ReactNode } from 'react'

import Overlay from '@vfuk/core-overlay'

import * as Styled from './styles/Container.style'

import { ContainerProps } from './Container.types'

export default class Container extends PureComponent<ContainerProps> {
  public static defaultProps: Partial<ContainerProps> = {
    elevation: false,
    appearance: 'primary',
    enableOverlay: true,
    paddingLevel: 3,
    allowOverflow: true,
  }

  public render(): ReactNode {
    return (
      <Styled.Container
        id={this.props.id}
        state={this.props.state}
        dropShadow={this.props.elevation!}
        appearance={this.props.appearance!}
        backgroundImage={this.props.backgroundImage}
        paddingLevel={this.props.paddingLevel}
        onInteraction={this.props.onClick}
        to={this.props.to}
        href={this.props.href}
        dataAttributes={this.props.dataAttributes}
        allowOverflow={this.props.allowOverflow}
      >
        <If condition={this.props.enableOverlay && this.props.backgroundImage}>
          <Overlay show position='absolute' zIndex={1} />
        </If>
        <Styled.ContainerContent>{this.props.children}</Styled.ContainerContent>
      </Styled.Container>
    )
  }
}
