import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledContainerProps } from './Container.style.types'

import localTheme from '../themes/Container.theme'

export const Container = styled(Interaction)((props: StyledContainerProps): FlattenSimpleInterpolation => {
  const appearanceFromProp = localTheme(props.theme).appearance[props.appearance!]

  return css`
    height: 100%;
    color: ${appearanceFromProp.color};
    background: ${appearanceFromProp.backgroundColor};
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: ${props.theme.border.radius[2]};
    position: relative;

    ${props.paddingLevel > 0 && css`
      padding: ${props.theme.spacing.responsive[props.paddingLevel].sm}px;

      ${props.theme.mixins.respondTo.md(css`
        padding: ${props.theme.spacing.responsive[props.paddingLevel].md}px;
      `)}

      ${props.theme.mixins.respondTo.lg(css`
        padding: ${props.theme.spacing.responsive[props.paddingLevel].lg}px;
      `)}
    `}

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: ${props.theme.border.radius[2]};
      pointer-events: none;
    }

    ${props.theme.mixins.elevation(props.dropShadow)}

    ${!props.dropShadow && !props.backgroundImage && appearanceFromProp.border.color !== 'transparent' && css`
      &::after {
        content: '';
        z-index: 1;
        border: ${props.theme.border.width[1]} ${props.theme.border.style[2]} ${appearanceFromProp.border.color};
      }
    `}

    ${((props.appearance === 'primary' || props.appearance === 'secondary') && props.state) && css`
      &::after {
        content: '';
        z-index: 1;
        border: ${props.theme.border.width[2]} solid ${props.theme.color[props.state!]}
      }
    `}

    ${props.backgroundImage && css`
      color: #fff;

      [class*='Icon'] {
        fill: #fff;
      }

      ${props.theme.mixins.bgImage(props.backgroundImage)}
    `}

    ${!props.allowOverflow && css`
      overflow: hidden;
    `}

    [class*=Overlay] {
      border-radius: ${props.theme.border.radius[2]};
    }
  `
})

export const ContainerContent = styled.div((): FlattenSimpleInterpolation => {
  return css`
    height: 100%;
    position: relative;
    z-index: 2;
  `
})
