import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { ContainerTheme } from './Container.theme.types'

const containerLocal = (theme: Theme): ContainerTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.baseFont.backgroundColor,
        color: theme.baseFont.color,
        border: {
          color: 'transparent',
        },
      },
      secondary: {
        backgroundColor: theme.baseFont.color,
        color: theme.baseFont.backgroundColor,
        border: {
          color: 'transparent',
        },
      },
      alt1: {
        backgroundColor: theme.color.secondary1,
        color: theme.baseFont.color,
        border: {
          color: 'transparent',
        },
      },
      alt2: {
        backgroundColor: theme.color.secondary3,
        color: theme.baseFont.color,
        border: {
          color: 'transparent',
        },
      },
      alt3: {
        backgroundColor: theme.color.secondary5,
        color: theme.baseFont.backgroundColor,
        border: {
          color: 'transparent',
        },
      },
      alt4: {
        backgroundColor: theme.baseFont.backgroundColor,
        color: theme.baseFont.color,
        border: {
          color: 'transparent',
        },
      },
    },
  }
}

export default containerLocal
