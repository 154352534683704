import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledDateTextInputWrapperProps } from './DateTextInputWrapper.style.types'

export const DateTextInputWrapper = styled.div((): FlattenSimpleInterpolation => css`
  position: relative;
`)

export const IconWrapper = styled.div((): FlattenSimpleInterpolation => css`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
`)

export const IconContainer = styled.div((props: StyledDateTextInputWrapperProps): FlattenSimpleInterpolation => css`
  padding-right: ${props.theme.spacing.fixed[3]}px;
  pointer-events: none;
  display: flex;
  align-items: center;
`)

export const DateTextInputLabel = styled.label((props: StyledDateTextInputWrapperProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.srOnly()}
`)
